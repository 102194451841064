import React, { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import { TextField, Button, Typography, Link, Container, Box } from '@mui/material';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      // Enviamos los datos al backend para registrar al usuario
      const response = await axios.post(`${process.env.REACT_APP_BACK_URL}/register`, {
        email,
        password,
      }, { withCredentials: true });

      setSuccess(true);
      setError('');
      setTimeout(() => {
        navigate('/login');  // Redirigir al login después de registrarse
      }, 2000);

    } catch (err) {
      setError(err.response.data.message);
    }
  };

  return (
    <Container maxWidth="xs">
      <Box sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Registrarse
        </Typography>
        {success ? (
          <Typography color="success">Registro exitoso. Redirigiendo al login...</Typography>
        ) : (
          <Box component="form" onSubmit={handleRegister} sx={{ mt: 1 }}>
            {error && <Typography color="error">{error}</Typography>}
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Registrarse
            </Button>
            <Typography variant="body2">
              ¿Ya tienes una cuenta? <Link component={RouterLink} to="/login">Inicia sesión aquí</Link>
            </Typography>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default Register;