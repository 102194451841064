import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { 
  Drawer,
  Box,
  SwipeableDrawer,
  Card,
  CardContent,
  Typography
} from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DetalleProducto from './DetalleProducto';
import Carrito from './Carrito';
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CrearProducto from './CrearProducto';
import AdminPanel from './AdminPanel';
import EditarProducto from './EditarProducto';
import axios from 'axios';
import HomePage from './HomePage';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useTheme } from '@mui/material/styles';
import HomePage2 from './HomePage2';
import Ventas from './Ventas';
import StockView from './StockView';
import { AuthProvider } from './AuthProvider';
import PrivateRoute from './PrivateRoute';
import Unauthorized from './Unauthorized';
import Login from './Login';
import Register from './Register';
import useMediaQuery from '@mui/material/useMediaQuery';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';

function App() {
  const [carrito, setCarrito] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [preferenceId, setPreferenceId] = useState(null);
  const [productos, setProductos] = useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
 
  useEffect(() => {
    initMercadoPago(process.env.REACT_APP_MP_PUBLIC_KEY , {
      locale: 'es-AR'
    });

    const fetchProductos = async () => {
      try {
        const backendUrl = process.env.REACT_APP_BACK_URL;

        const response = await axios.get(`${backendUrl}/productos`, { withCredentials: true });
        const productos = response.data;

        for (const producto of productos) {
          const imagenesResponse = await axios.get(`${backendUrl}/imagenes/${producto.id}`, { withCredentials: true });
          
          // Verificar si response.data es un arreglo
          if (Array.isArray(imagenesResponse.data)) {
            producto.imagenes = imagenesResponse.data.map((imagen) => imagen.ruta_imagen);
          } else {
            console.error('La respuesta de imágenes no es un array:', imagenesResponse.data);
          }
        }

        setProductos(productos);

      } catch (error) {
        console.error('Error fetching productos:', error);
      }
    };

    fetchProductos();
  }, []);

  const totalCantidadCarrito = carrito.reduce((total, item) => total + item.cantidad, 0);

  const agregarAlCarrito = (producto, cantidad, talla, color, stock, mainImage) => {
    const productoExistente = carrito.find(
      (item) => item.id === producto.id && item.talla.id === talla.id && item.color.id === color.id
    );
  
    if (productoExistente) {
      if (productoExistente.cantidad + cantidad > stock) {
        alert('No hay suficiente stock para la cantidad seleccionada.');
        return;
      }
      const carritoActualizado = carrito.map((item) =>
        item.id === producto.id && item.talla.id === talla.id && item.color.id === color.id
          ? { ...item, cantidad: item.cantidad + cantidad }
          : item
      );
      setCarrito(carritoActualizado);
    } else {
      if (cantidad > stock) {
        alert('No hay suficiente stock para la cantidad seleccionada.');
        return;
      }
      setCarrito([...carrito, { ...producto, cantidad, talla, color, stock, mainImage }]);
    }
  };

  return (
    <AuthProvider>
      <Router>
        <Box sx={{ 
          minHeight: '100vh',
          backgroundColor: '#f5f5f5',
          backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8))',
          backgroundSize: 'cover',
          width: '100%',
          overflowX: 'hidden'
        }}>
          {isMobile ? (
            <HeaderMobile totalCantidadCarrito={totalCantidadCarrito} setDrawerOpen={setDrawerOpen} />
          ) : (
            <HeaderDesktop totalCantidadCarrito={totalCantidadCarrito} setDrawerOpen={setDrawerOpen} />
          )}

            <Routes>
              <Route path="/" element={<HomePage2 />} />
              <Route path="/producto/:producto_id" element={<DetalleProducto agregarAlCarrito={agregarAlCarrito} carrito={carrito} />} />
              
              {/* Rutas de login y registro */}
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />

              {/* Rutas protegidas por rol de administrador */}
              <Route path="/admin/crear_producto" element={<PrivateRoute roles={['admin']} element={<CrearProducto />} />} />
              <Route path="/admin/*" element={<PrivateRoute roles={['admin']} element={<AdminPanel />} />} />
              <Route path="/admin/editar_producto/:productoId" element={<PrivateRoute roles={['admin']} element={<EditarProducto />} />} />
              <Route path="/admin/ventas" element={<PrivateRoute roles={['admin']} element={<Ventas />} />} />
              <Route path="/admin/stock" element={<PrivateRoute roles={['admin']} element={<StockView />} />} />

              {/* Ruta de Unauthorized en caso de no tener acceso */}
              <Route path="/unauthorized" element={<Unauthorized />} />
            </Routes>

          
            {isMobile ? (
            <SwipeableDrawer
              anchor="bottom"
              open={drawerOpen}
              onClose={() => setDrawerOpen(false)}
              onOpen={() => setDrawerOpen(true)}
              PaperProps={{
                sx: { width: '100%', height: '50%' }
              }}
            >
              <Carrito carrito={carrito} setCarrito={setCarrito} />
              {preferenceId && (
                <Box sx={{ p: 2 }}>
                  <Wallet initialization={{ preferenceId: preferenceId }} />
                </Box>
              )}
            </SwipeableDrawer>
          ) : (
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={() => setDrawerOpen(false)}
              PaperProps={{
                sx: { width: { xs: '90%', sm: 600 } }
              }}
            >
              <Carrito carrito={carrito} setCarrito={setCarrito} />
              {preferenceId && (
                <Box sx={{ p: 2 }}>
                  <Wallet initialization={{ preferenceId: preferenceId }} />
                </Box>
              )}
            </Drawer>
          )}
        </Box>
      </Router>
    </AuthProvider>
  );
}

export default App;