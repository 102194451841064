import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { 
  Card, 
  CardContent, 
  Table, 
  TableHead, 
  TableRow, 
  TableCell, 
  TableBody, 
  TableContainer,
  Typography,
  useTheme 
} from '@mui/material';

const StockView = () => {
  const [stockData, setStockData] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    const fetchStockData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/stock`, { withCredentials: true });
        setStockData(response.data);
      } catch (error) {
        console.error('Error fetching stock data:', error);
      }
    };

    fetchStockData();
  }, []);

  const getUniqueValues = (key) => {
    return [...new Set(stockData.map(item => item[key]))];
  };

  const productos = getUniqueValues('producto');
  const colores = getUniqueValues('color');
  const tallas = getUniqueValues('talla');

  // Estilos comunes para las celdas del header
  const headerCellStyle = {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    '&.MuiTableCell-stickyHeader': {
      backgroundColor: theme.palette.primary.main,
    },
    textAlign: 'center',
    minWidth: '100px',
    padding: '8px',
    fontSize: {
      xs: '0.75rem',
      sm: '0.875rem',
    }
  };

  // Estilo para las celdas del cuerpo
  const bodyCellStyle = {
    textAlign: 'center',
    borderRight: '1px solid #ddd',
    padding: '8px',
    fontSize: {
      xs: '0.75rem',
      sm: '0.875rem',
    }
  };

  // Función para determinar si es la última talla de un producto
  const isLastSizeOfProduct = (productoIndex, tallaIndex) => {
    return tallaIndex === tallas.length - 1;
  };

  return (
    <Card>
      <CardContent>
        <TableContainer 
          sx={{ 
            overflowX: 'auto',
            maxHeight: '80vh',
            '&::-webkit-scrollbar': {
              height: '8px',
              width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '4px',
            }
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell 
                  sx={{
                    ...headerCellStyle,
                    borderRight: '2px solid #ddd',
                    position: 'sticky',
                    left: 0,
                    zIndex: 3
                  }}
                >
                  Color / Talla
                </TableCell>
                {productos.map((producto, productoIndex) => (
                  <TableCell 
                    key={producto} 
                    colSpan={tallas.length} 
                    sx={{
                      ...headerCellStyle,
                      borderRight: '2px solid white' // Línea blanca entre productos
                    }}
                  >
                    {producto}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell 
                  sx={{
                    backgroundColor: theme.palette.primary.light,
                      color: theme.palette.secondary.main,
                      '&.MuiTableCell-stickyHeader': {
                        backgroundColor: theme.palette.primary.light,
                      },
                      textAlign: 'center',
                      minWidth: '100px',
                      padding: '8px',
                      fontSize: {
                        xs: '0.75rem',
                        sm: '0.875rem',
                      },
                    borderRight: '2px solid #ddd',
                    position: 'sticky',
                    left: 0,
                    zIndex: 3
                  }}
                />
                {productos.map((producto, productoIndex) => (
                  tallas.map((talla, tallaIndex) => (
                    <TableCell 
                      key={`${producto}-${talla}`} 
                      sx={{
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.secondary.main,
                        '&.MuiTableCell-stickyHeader': {
                          backgroundColor: theme.palette.primary.light,
                        },
                        textAlign: 'center',
                        minWidth: '100px',
                        padding: '8px',
                        fontSize: {
                          xs: '0.75rem',
                          sm: '0.875rem',
                        },
                        borderRight: isLastSizeOfProduct(productoIndex, tallaIndex) 
                          ? '2px solid white' // Línea blanca para la última talla de cada producto
                          : '1px solid #ddd'
                      }}
                    >
                      {talla}
                    </TableCell>
                  ))
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {colores.map(color => (
                <TableRow key={color}>
                  <TableCell 
                    sx={{
                      ...bodyCellStyle,
                      backgroundColor: theme.palette.primary.light,
                      color: theme.palette.secondary.main,
                      borderRight: '2px solid #ddd',
                      position: 'sticky',
                      left: 0,
                      zIndex: 2
                    }}
                  >
                    {color}
                  </TableCell>
                  {productos.map((producto, productoIndex) => (
                    tallas.map((talla, tallaIndex) => {
                      const stockItem = stockData.find(
                        item => item.producto === producto && 
                        item.color === color && 
                        item.talla === talla
                      );
                      return (
                        <TableCell 
                          key={`${color}-${producto}-${talla}`} 
                          sx={{
                            ...bodyCellStyle,
                            borderRight: isLastSizeOfProduct(productoIndex, tallaIndex)
                              ? '2px solid white' // Línea blanca para la última talla de cada producto
                              : '1px solid #ddd'
                          }}
                        >
                          {stockItem ? stockItem.stock : 0}
                        </TableCell>
                      );
                    })
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default StockView;