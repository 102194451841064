import React, { createContext, useState, useEffect, useContext } from 'react';
import {jwtDecode} from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // Verificar si el token ya está guardado
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedUser = jwtDecode(token);
        setUser(decodedUser);
      } catch (error) {
        console.error('Token no válido', error);
        localStorage.removeItem('token');
        localStorage.removeItem('role');
      }
    }
  }, []);

  // Función para iniciar sesión
  const login = (userData, token) => {
    try {
      const decodedUser = jwtDecode(token);
      setUser(decodedUser);
      localStorage.setItem('token', token);
      localStorage.setItem('role', userData.role); // Guardamos el rol
    } catch (error) {
      console.error('Token no válido', error);
    }
  };

  // Función para cerrar sesión
  const logout = () => {
    setUser(null);
    localStorage.removeItem('token');
    localStorage.removeItem('role'); // Limpiar el rol
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);