import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  IconButton, 
  Drawer,
  Container,
  Paper,
  Box,
  Divider
} from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Importa los estilos del carrusel
import { Carousel } from 'react-responsive-carousel';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import './index.css';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function HomePage({productos}) {
  const [categorias, setCategorias] = useState([]);
  const theme = useTheme();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/categorias`, { withCredentials: true });
        setCategorias(response.data);
      } catch (error) {
        console.error('Error fetching categorias:', error);
      }
    };

    fetchCategorias();
  }, []);

  return (
    <>
        {/* Hero Section */}
        <Paper sx={{ 
        position: 'relative',
        backgroundColor: '#211915',
        color: 'white',
        padding: '4rem 2rem',
        textAlign: 'center',
        marginBottom: 0
        }}>
        <Container>
            <Typography variant="h3" component="h1" gutterBottom sx={{ 
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 700
            }}>
            One Way
            </Typography>
            <Typography variant="h5" gutterBottom>
            Ropa con un mensaje de fe
            </Typography>
            <Typography variant="body1" sx={{ maxWidth: '600px', margin: '0 auto' }}>
            Somos diseñadores de indumentaria y fabricamos ropa urbana con un mensaje de fe.
            </Typography>
        </Container>
        </Paper>

        {/* Carrusel de imágenes */}
        <Container maxWidth="lg" sx={{ mb: 4, mt: 0, padding: 0 }}>
          <Slider {...settings}>
            <div>
              <img src="/img_header/header1.jpeg" alt="Imagen 1" style={{ width: '100%', height: '300px', objectFit: 'cover' }} />
            </div>
            <div>
              <img src="/img_header/header2.jpeg" alt="Imagen 2" style={{ width: '100%', height: '300px', objectFit: 'cover' }} />
            </div>
            <div>
              <img src="/img_header/header3.jpeg" alt="Imagen 2" style={{ width: '100%', height: '300px', objectFit: 'cover' }} />
            </div>
            <div>
              <img src="/img_header/header4.jpeg" alt="Imagen 2" style={{ width: '100%', height: '300px', objectFit: 'cover' }} />
            </div>
            <div>
              <img src="/img_header/header5.jpeg" alt="Imagen 2" style={{ width: '100%', height: '300px', objectFit: 'cover' }} />
            </div>
          </Slider>
        </Container>
        
        {/* Products Section */}
        <Container maxWidth="lg" sx={{ py: 4 }}>
        {categorias.map((categoria) => (
            <Box key={categoria.id}>
            <Typography variant="h4" component="h2" gutterBottom sx={{ 
                textAlign: 'center',
                mb: 4,
                fontFamily: 'Montserrat, sans-serif',
            }}>
                {categoria.nombre}
            </Typography>
            <Grid container spacing={4}>
                {productos.filter(producto => producto.categoria_id === categoria.id).map((producto) => (
                <Grid item xs={12} sm={6} md={4} key={producto.id}>
                    <Card sx={{ 
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    transition: '0.3s',
                    '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: 3
                    }
                    }}>
                    <Link to={`/producto/${producto.id}`} style={{ textDecoration: 'none' }}>
                        <Box sx={{ 
                        position: 'relative',
                        overflow: 'hidden'
                        }}>
                        <Carousel 
                            showThumbs={false} 
                            autoPlay 
                            infiniteLoop 
                            showStatus={false}
                            showArrows={false}
                        >
                            {producto.imagenes.map((imagen, index) => (
                            <div key={index}>
                                <img 
                                src={imagen} 
                                alt={`${producto.nombre} ${index + 1}`}
                                style={{
                                    width: '100%',
                                    height: '400px',
                                    objectFit: 'cover'
                                }}
                                />
                            </div>
                            ))}
                        </Carousel>
                        </Box>
                    </Link>
                    <CardContent sx={{ flexGrow: 1 }}>
                        <Typography variant="h6" gutterBottom component={Link} to={`/producto/${producto.id}`} sx={{
                        textDecoration: 'none',
                        color: 'inherit',
                        fontWeight: 600
                        }}>
                        {producto.nombre}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" paragraph>
                        {producto.descripcion}
                        </Typography>
                        {producto.precio_descuento ? (
                        <>
                            <Typography variant="body2" color="text.secondary" sx={{ textDecoration: 'line-through' }}>
                            ${producto.precio}
                            </Typography>
                            <Typography variant="h6" color="text.primary" display="inline">
                            ${producto.precio_descuento}
                            </Typography>
                            <Typography variant="body2" color="success.main" display="inline" sx={{ marginLeft: 1 }}>
                            {Math.round(((producto.precio - producto.precio_descuento) / producto.precio) * 100)}% OFF
                            </Typography>
                        </>
                        ) : (
                        <Typography variant="h6" color="text.primary" display="inline">
                            ${producto.precio}
                        </Typography>
                        )}
                    </CardContent>
                    </Card>
                </Grid>
                ))}
            </Grid>
            <Divider sx={{ my: 4 }} />
            </Box>
        ))}
        </Container>

        {/* Footer */}
        <Box component="footer" sx={{ 
        backgroundColor: '#211915',
        color: 'white',
        py: 6,
        mt: 'auto'
        }}>
        <Container maxWidth="lg">
            <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
                <Typography variant="h6" gutterBottom>
                Sobre One Way
                </Typography>
                <Typography variant="body2">
                Somos una marca de ropa urbana comprometida con la calidad y el estilo. 
                Nuestra misión es ayudarte a expresar tu personalidad a través de la moda.
                </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
                <Typography variant="h6" gutterBottom>
                Contacto
                </Typography>
                <Typography variant="body2">
                Email: contacto@oneway.com<br />
                Teléfono: (123) 456-7890<br />
                Dirección: Cabildo 225, Turdera
                </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
                <Typography variant="h6" gutterBottom>
                    Seguinos
                </Typography>
                <Box sx={{ mt: 2 }}>
                    <a href="https://www.instagram.com/oneway.style" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <IconButton
                        color="inherit"
                        sx={{
                        '&:hover': {
                            color: theme.palette.secondary.main,
                        },
                        }}
                    >
                        <InstagramIcon />
                    </IconButton>
                    </a>
                    <a href="https://www.facebook.com/oneway.style" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <IconButton
                        color="inherit"
                        sx={{
                        '&:hover': {
                            color: theme.palette.secondary.main,
                        },
                        }}
                    >
                        <FacebookIcon />
                    </IconButton>
                    </a>
                    <a href="https://api.whatsapp.com/send/?phone=5492235993095&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <IconButton
                        color="inherit"
                        sx={{
                        '&:hover': {
                            color: theme.palette.secondary.main,
                        },
                        }}
                    >
                        <WhatsAppIcon />
                    </IconButton>
                    </a>
                </Box>
                </Grid>
            </Grid>
            <Divider sx={{ my: 4, backgroundColor: 'rgba(255,255,255,0.1)' }} />
            <Typography variant="body2" align="center">
            © {new Date().getFullYear()} One Way. Todos los derechos reservados.
            </Typography>
        </Container>
        </Box>
    </>
  );
};

export default HomePage;