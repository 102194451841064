import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  useTheme,
} from '@mui/material';

const Ventas = () => {
  const [ventas, setVentas] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    const fetchVentas = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/ventas`, {
          withCredentials: true,
        });
        setVentas(response.data);
      } catch (error) {
        console.error('Error fetching ventas:', error);
      }
    };

    fetchVentas();
  }, []);

  return (
    <Card>
      <CardContent>
        <TableContainer style={{ overflowX: 'auto', maxHeight: '400px' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {/* Aplicar el estilo a cada TableCell individualmente */}
                <TableCell 
                  sx={{ 
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.secondary.main,
                    // Asegurarse que el color se mantenga al hacer scroll
                    '&.MuiTableCell-stickyHeader': {
                      backgroundColor: theme.palette.primary.main,
                    }
                  }}
                >
                  ID
                </TableCell>
                <TableCell 
                  sx={{ 
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.secondary.main,
                    '&.MuiTableCell-stickyHeader': {
                      backgroundColor: theme.palette.primary.main,
                    }
                  }}
                >
                  Nombre del Pagador
                </TableCell>
                <TableCell 
                  sx={{ 
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.secondary.main,
                    '&.MuiTableCell-stickyHeader': {
                      backgroundColor: theme.palette.primary.main,
                    }
                  }}
                >
                  Email del Pagador
                </TableCell>
                <TableCell 
                  sx={{ 
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.secondary.main,
                    '&.MuiTableCell-stickyHeader': {
                      backgroundColor: theme.palette.primary.main,
                    }
                  }}
                >
                  ID del Pagador
                </TableCell>
                <TableCell 
                  sx={{ 
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.secondary.main,
                    '&.MuiTableCell-stickyHeader': {
                      backgroundColor: theme.palette.primary.main,
                    }
                  }}
                >
                  Tipo de ID del Pagador
                </TableCell>
                <TableCell 
                  sx={{ 
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.secondary.main,
                    '&.MuiTableCell-stickyHeader': {
                      backgroundColor: theme.palette.primary.main,
                    }
                  }}
                >
                  Productos
                </TableCell>
                <TableCell 
                  sx={{ 
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.secondary.main,
                    '&.MuiTableCell-stickyHeader': {
                      backgroundColor: theme.palette.primary.main,
                    }
                  }}
                >
                  Precio Total
                </TableCell>
                <TableCell 
                  sx={{ 
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.secondary.main,
                    '&.MuiTableCell-stickyHeader': {
                      backgroundColor: theme.palette.primary.main,
                    }
                  }}
                >
                  Fecha
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* El resto del código permanece igual */}
              {ventas.map((venta) => (
                <TableRow key={venta.id}>
                  <TableCell>{venta.id}</TableCell>
                  <TableCell>{venta.payer_nombre}</TableCell>
                  <TableCell>{venta.payer_email}</TableCell>
                  <TableCell>{venta.payer_id}</TableCell>
                  <TableCell>{venta.payer_id_type}</TableCell>
                  <TableCell>
                    {venta.productos.split(';').map((producto, index) => (
                      <div key={index}>{producto.trim()}</div>
                    ))}
                  </TableCell>
                  <TableCell>${venta.precio_total}</TableCell>
                  <TableCell>{new Date(venta.fecha).toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default Ventas;