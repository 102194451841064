import React from 'react';
import { AppBar, Box, IconButton, Toolbar, Typography, useTheme } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link } from 'react-router-dom';

const HeaderMobile = ({ totalCantidadCarrito, setDrawerOpen }) => {
  const theme = useTheme();

  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#ffff' }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Link to="/" style={{ textDecoration: 'none', marginTop: '10px' }}>
          <img src="/one_way.png" alt="ONE WAY" style={{ height: '40px', maxWidth: '150px' }} />
        </Link>
        <IconButton color="inherit" onClick={() => setDrawerOpen(true)}>
          <ShoppingCartIcon sx={{ color: '#211915' }} />
          <Typography variant="body1" sx={{ ml: 1, color: '#211915' }}>
            ({totalCantidadCarrito})
          </Typography>
        </IconButton>
      </Toolbar>
      <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'center', width: '100%' }}>
          <Typography variant="body1" sx={{ color: theme.palette.primary.main }}>
            Seguinos en
          </Typography>
          <a href="https://www.instagram.com/oneway.style" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
            <IconButton
              color="inherit"
              sx={{
                color: theme.palette.primary.main,
                ml: -2,
                '&:hover': {
                  color: theme.palette.secondary.main,
                },
              }}
            >
              <InstagramIcon />
            </IconButton>
          </a>
          <Typography variant="body1" sx={{ color: theme.palette.primary.main }}>
            Consultas al
          </Typography>
          <a href="https://api.whatsapp.com/send/?phone=5492235993095&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
            <IconButton
              color="inherit"
              sx={{
                color: theme.palette.primary.main,
                ml: -2,
                '&:hover': {
                  color: theme.palette.secondary.main,
                },
              }}
            >
              <WhatsAppIcon />
            </IconButton>
          </a>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderMobile;