import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';

const PrivateRoute = ({ element, roles }) => {
  const { user } = useAuth();
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');

  // Si no hay token, redirigimos a login
  if (!token) {
    return <Navigate to="/login" />;
  }

  // Si el usuario no tiene el rol adecuado
  if (roles && !roles.includes(role)) {
    return <Navigate to="/unauthorized" />;
  }

  // Si el usuario está autenticado y tiene el rol adecuado, mostrar la ruta
  return element;
};

export default PrivateRoute;