import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { 
  Typography, 
  Button, 
  Grid, 
  CardMedia, 
  TextField, 
  MenuItem, 
  IconButton, 
  InputBase, 
  Paper,
  Box,
  Breadcrumbs,
  Link,
  Tabs,
  Tab,
  Rating,
  Chip,
  Snackbar,
  Alert,
  Divider,
  Zoom,
  CircularProgress,
  Card,
  CardContent,
  Dialog, DialogContent
} from '@mui/material';
import { 
  Add, 
  Remove, 
  ArrowBack,
  LocalShipping,
  Security,
  Loop,
  Favorite,
  FavoriteBorder,
  Storefront,
  Home as HomeIcon
} from '@mui/icons-material';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { CSSTransition } from 'react-transition-group';
import './styles.css';
import InfoIcon from '@mui/icons-material/Info';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function DetalleProducto({agregarAlCarrito, carrito }) {
  const { producto_id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const productos = location.state?.productos || [];
  const producto = productos.find(p => p.id === parseInt(producto_id));
  const [searchParams] = useSearchParams();
  
  const [talla, setTalla] = useState({id: '', nombre: ''});
  const [cantidad, setCantidad] = useState(1);
  const [tabValue, setTabValue] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [mainImage, setMainImage] = useState('');
  const [tallas, setTallas] = useState([]);
  const [colores, setColores] = useState([]);
  const [color, setColor] = useState({id: '', nombre: ''});
  const [stock, setStock] = useState(0);
  const [stockInicial, setStockInicial] = useState(0);
  const [imagesByColor, setImagesByColor] = useState({});
  const [currentImages, setCurrentImages] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [stockMatrix, setStockMatrix] = useState({});
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  
  // Función para obtener el stock del producto
  async function fetchStock(productoId) {
    const response = await fetch(`${process.env.REACT_APP_BACK_URL}/stock/${productoId}`, { withCredentials: true });
    const stockData = await response.json();
    return stockData;
  }

  // Función para crear la matriz asociativa del stock
  function createStockMatrix(stockData) {
    const stockMatrix = {};
    stockData.forEach(({ talla_id, color_id, cant_stock }) => {
      if (!stockMatrix[color_id]) {
        stockMatrix[color_id] = {};
      }
      stockMatrix[color_id][talla_id] = cant_stock;
    });
    return stockMatrix;
  }

  useEffect(() => {

    const fetchProducto = async () => {
      try {
        if (producto) {
          // Crear matriz de stock
          async function loadStock() {
            const stockData = await fetchStock(producto.id);
            const matrix = createStockMatrix(stockData);
            setStockMatrix(matrix);
          }
          loadStock();
    
          // Establecer imagen principal
          if (producto.colores.length > 0 && producto.colores[0].imagenes.length > 0) {
            setMainImage(producto.colores[0].imagenes[0].ruta_imagen);
          }
    
          // Establecer tallas y colores
          setTallas(producto.tallas);
          setColores(producto.colores);
    
          // Organizar imágenes por color
          const imagesByColorTemp = {};
          producto.colores.forEach(color => {
            imagesByColorTemp[color.id] = color.imagenes.map(imagen => imagen.ruta_imagen);
          });
          setImagesByColor(imagesByColorTemp);
          setCurrentImages(imagesByColorTemp[producto.colores[0].id] || []);

          // Obtener el parámetro de consulta 'color'
          const defaultColorId = searchParams.get('color');
          let defaultColor = null;

          if (defaultColorId) {
            defaultColor = producto.colores.find(color => color.id === parseInt(defaultColorId));
          }

          // Establecer el color por defecto (primer color)
          if (producto.colores.length > 0) {
            const selectedColor = defaultColor || producto.colores[0];
            setColor(selectedColor);
            // Establecer las imágenes del color por defecto
            const defaultImages = imagesByColorTemp[selectedColor.id] || [];
            setCurrentImages(defaultImages);
            if (defaultImages.length > 0) {
              setMainImage(defaultImages[0]);
            }
          }
        }
      }
      catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducto();
  }, [producto]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateStock = async (color_id, talla_id) => {
    if (color && talla) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/productos/${producto.id}/stock`, {
          params: { talla_id: talla_id, color_id: color_id },
          withCredentials: true
        });

        const productoExistente = carrito.find(
          (item) => item.id === producto.id && item.talla.id === talla_id && item.color.id === color_id
        );
        if (productoExistente) {
          setStock(response.data.stock - productoExistente.cantidad);
        }
        else {
          setStock(response.data.stock);
        }

      } catch (error) {
        console.error('Error al obtener el stock del producto:', error);
      }
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!loading && !producto) {
    return (
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        p: 4,
        mt: isMobile ? 14 : 8
      }} >
        <Typography variant="h4" gutterBottom>Producto no encontrado</Typography>
        <Button 
          startIcon={<ArrowBack />} 
          onClick={() => navigate('/')}
          variant="contained"
        >
          Volver al inicio
        </Button>
      </Box>
    );
  }

  const handleAgregar = () => {
    if (talla.id === '') {
      setSnackbarMessage('Por favor selecciona una talla.');
      setSnackbarSeverity('error');
      setShowSnackbar(true);
      return;
    }

    if (color.id === '') {
      setSnackbarMessage('Por favor selecciona un color.');
      setSnackbarSeverity('error');
      setShowSnackbar(true);
      return;
    }

    const productoExistente = carrito.find(
      (item) => item.id === producto.id && item.talla.id === talla.id && item.color.id === color.id
    );
  
    if (productoExistente) {
      if (productoExistente.cantidad + cantidad > stock) {
        setSnackbarMessage('No hay suficiente stock para la cantidad seleccionada.');
        setSnackbarSeverity('error');
        setShowSnackbar(true);
        return;
      }
      setStock(stock - productoExistente.cantidad);
    } else {
      if (cantidad > stock) {
        setSnackbarMessage('No hay suficiente stock para la cantidad seleccionada.');
        setSnackbarSeverity('error');
        setShowSnackbar(true);
        return;
      }
      setStock(stock - cantidad);
    }
    
    // Obtener la primera imagen del color seleccionado
    const colorImages = imagesByColor[color.id] || [];
    const mainImage = colorImages[0];

    setSnackbarMessage('Producto agregado al carrito');
    setSnackbarSeverity('success');
    setShowSnackbar(true);

    agregarAlCarrito(producto, cantidad, talla, color, stock, mainImage);
    setShowSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
  };

  const aumentarCantidad = () => setCantidad(prev => prev + 1);
  const reducirCantidad = () => setCantidad(prev => prev > 1 ? prev - 1 : 1);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleColorChange = (selectedColor) => {
    setColor(selectedColor);
    const newImages = imagesByColor[selectedColor.id] || [];
    setCurrentImages(newImages);
    setTalla({id: '', nombre: ''});
    if (newImages.length > 0) {
      setMainImage(newImages[0]);
    }
    if(talla.id !== '') {
      updateStock(selectedColor.id, talla.id);
    }
  };
  
  const handleTallaChange = (selectedTalla) => {
    setTalla(selectedTalla);
    if(color.id != '') {
      updateStock(color.id, selectedTalla.id);
    }
  };

  function isInStock(colorId, tallaId) {
    return stockMatrix[colorId] && stockMatrix[colorId][tallaId] > 0;
  }

  const removeDuplicates = (array, key) => {
    return [...new Map(array.map(item => [item[key], item])).values()];
  };

  return (
    <Box sx={{ p: { xs: 2, md: 4 }, mt: isMobile ? 14 : 8 }}>
      {/* Breadcrumbs */}
      <Breadcrumbs sx={{ mb: 3 }}>
        <Link 
          component="button"
          underline="hover"
          color="inherit"
          onClick={() => navigate('/')}
        >
          Inicio
        </Link>
        <Typography color="text.primary">{producto.nombre}</Typography>
      </Breadcrumbs>

      <Grid container spacing={4}>
        {/* Columna de imágenes */}
        <Grid item xs={12} md={4.8}>
          <Box sx={{ position: 'relative' }}>
          <CSSTransition
            in={true}
            appear={true}
            timeout={300}
            classNames="fade"
            key={mainImage} 
          >
            <CardMedia
              component="img"
              image={mainImage}
              alt={producto.nombre}
              sx={{
                width: isMobile ? '65%' : '65%',
                height: isMobile ? '65%' : '65%',
                margin: '0 auto',
                objectFit: 'cover',
                borderRadius: 2,
                boxShadow: 3
              }}
            />
            </CSSTransition>
            {/* <IconButton
              onClick={() => setIsFavorite(!isFavorite)}
              sx={{
                position: 'absolute',
                top: 10,
                right: 10,
                backgroundColor: 'white',
                '&:hover': { backgroundColor: 'rgba(255,255,255,0.9)' }
              }}
            >
              {isFavorite ? <Favorite color="error" /> : <FavoriteBorder />}
            </IconButton> */}
          </Box>
          
          {/* Miniaturas */}
          <Box sx={{ 
            display: 'flex', 
            gap: 2, 
            mt: 2, 
            justifyContent: 'center',
            flexWrap: 'wrap'
          }}>
            {currentImages.map((img, index) => (
              <Box
                key={index}
                component="img"
                src={img}
                alt={`Imagen ${index + 1}`}
                sx={{
                  width: '20%',
                  height: '20%',
                  objectFit: 'cover',
                  borderRadius: 1,
                  cursor: 'pointer',
                  opacity: mainImage === img ? 1 : 0.6,
                  transition: '0.3s',
                  '&:hover': { opacity: 1, transform: 'scale(1.05)' }
                }}
                onClick={() => setMainImage(img)}
              />
            ))}
          </Box>
        </Grid>

        {/* Detalles del producto */}
        <Grid item xs={12} md={7.2}>
          <Box sx={{ mb: 3 }}>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 600 }}>
              {producto.nombre}
            </Typography>
            {producto.precio_descuento ? (
              <>
                <Typography variant="h6" color="text.secondary" sx={{ textDecoration: 'line-through' }}>
                  ${producto.precio}
                </Typography>
                <Typography variant="h4" color="text.primary" display="inline">
                  ${producto.precio_descuento}
                </Typography>
                <Typography variant="h6" color="success.main" display="inline" sx={{ marginLeft: 1 }}>
                  {Math.round(((producto.precio - producto.precio_descuento) / producto.precio) * 100)}% OFF
                </Typography>
              </>
            ) : (
              <Typography variant="h4" color="primary" sx={{ mb: 2 }}>
                ${producto.precio}
              </Typography>
            )}
            {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
              <Rating value={4.5} precision={0.5} readOnly />
              <Typography variant="body2" color="text.secondary">
                (128 reseñas)
              </Typography>
            </Box> */}
            <Typography variant="body1" color="text.secondary" paragraph >
              {producto.descripcion || "Descubre nuestro exclusivo diseño que combina estilo y comodidad. Fabricado con materiales de alta calidad para garantizar durabilidad y un ajuste perfecto."}
            </Typography>
          </Box>

          <Divider sx={{ my: 3 }} />
              
          {/* Selector de talla */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Tallas
              <IconButton onClick={handleClickOpen}>
                <InfoIcon />
              </IconButton>
            </Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              {tallas.map((talla2) => {
                const inStock = isInStock(color.id, talla2.id);
                return (
                  <Box key={talla2.id} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Chip
                      label={talla2.nombre}
                      onClick={() => inStock && handleTallaChange(talla2)}
                      sx={{
                        minWidth: 60,
                        cursor: inStock ? 'pointer' : 'not-allowed',
                        backgroundColor: talla2.id === talla.id ? theme.palette.primary.main : 'default',
                        color: talla2.id === talla.id ? '#fff' : 'default',
                        textDecoration: inStock ? 'none' : 'line-through',
                        '&:hover': {
                          backgroundColor: isMobile ? theme.palette.primary.main : theme.palette.secondary.main,
                        },
                      }}
                      disabled={!inStock}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>

          <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogContent sx={{ padding: 0, overflow: 'auto' }}>
              <img src="https://res.cloudinary.com/dkju2tkpd/image/upload/v1733802009/tabla_talles_buzos_z4etmm.jpg" alt="Tabla de Talles" style={{ width: '100%', height: 'auto', display: 'block' }} />
            </DialogContent>
          </Dialog>


            {/* Selector de color */}
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" gutterBottom>
                Colores
              </Typography>
              <Box sx={{ display: 'flex', gap: 1 }}>
                {colores.map((color2) => (
                  <Chip
                  key={color2.id}
                  label={color2.nombre}
                  onClick={() => handleColorChange(color2)}
                  sx={{
                    minWidth: 60,
                    cursor: 'pointer',
                    backgroundColor: color2.id === color.id ? theme.palette.primary.main : 'default',
                    color: color2.id === color.id ? '#fff' : 'default',
                    '&:hover': {
                        backgroundColor: isMobile ? theme.palette.primary.main : theme.palette.secondary.main, // Cambiar color al pasar el mouse
                      },
                  }}
                />
                ))}
              </Box>
            </Box>

          {/* Selector de cantidad */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Cantidad
            </Typography>
            <Paper 
              elevation={0} 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                maxWidth: 150,
                border: 1,
                borderColor: 'divider',
                borderRadius: 8
              }}
            >
              <IconButton onClick={reducirCantidad} size="small">
                <Remove />
              </IconButton>
              <InputBase
                value={cantidad}
                inputProps={{ 
                  style: { 
                    textAlign: 'center',
                    width: '80px'
                  } 
                }}
                readOnly
              />
              <IconButton onClick={aumentarCantidad} size="small">
                <Add />
              </IconButton>
            </Paper>
          </Box>
          
          {color.id != '' && talla.id != '' && (
            <Typography variant="body2" color="textSecondary">
              Stock disponible: {stock}
            </Typography>
          )}

          {/* Botón de compra */}
          <Button
            variant="contained"
            size="large"
            onClick={handleAgregar}
            fullWidth
            sx={{ 
              mb: 2,
              py: 1.5,
              borderRadius: 8,
              marginTop: 1,
              textTransform: 'none',
              fontSize: '1.1rem',
              transition: '0.3s',
              '&:hover': { opacity: 1, transform: 'scale(1.01)' },
              '@media (hover: none)': {
                '&:hover': { 
                  opacity: 1, 
                  transform: 'none' 
                }
              }
            }}
          >
            Agregar al carrito
          </Button>

          <Card sx={{ borderRadius: 2, marginTop: 2, padding: 2, backgroundColor: 'secondary.main', color: 'white' }}>
            <CardContent>
              <Typography variant="body1">
                Si pagas por transferencia, tenés un 10% de descuento. Escribinos al WhatsApp ;)
              </Typography>
            </CardContent>
          </Card>

          {/* Beneficios */}
          <Box sx={{ mt: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  flexDirection: 'column',
                  textAlign: 'center'
                }}>
                  <LocalShipping color="primary" sx={{ mb: 1 }} />
                  <Typography variant="body2">Envíos a todo el país</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  flexDirection: 'column',
                  textAlign: 'center'
                }}>
                  <Security color="primary" sx={{ mb: 1 }} />
                  <Typography variant="body2">Pago seguro</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  flexDirection: 'column',
                  textAlign: 'center'
                }}>
                  <HomeIcon color="primary" sx={{ mb: 1 }} />
                  <Typography variant="body2">Retiro en domicilio</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      {/* Tabs de información adicional */}
      <Box sx={{ mt: 6 }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange}
          centered
          sx={{
            borderBottom: 1,
            borderColor: 'divider'
          }}
        >
          <Tab label="Especificaciones" />
        </Tabs>

        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2">Material</Typography>
              <Typography paragraph>{producto.material}</Typography>
              
              <Typography variant="subtitle2">Peso</Typography>
              <Typography paragraph>{producto.peso} Kg</Typography>
              
              
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2">País de origen</Typography>
              <Typography paragraph>Argentina</Typography>
            </Grid>
          </Grid>
        </TabPanel>
        
        
      </Box>

      {/* Snackbar de confirmación */}
      <Snackbar 
        open={showSnackbar} 
        autoHideDuration={3000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbarSeverity}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default DetalleProducto;