import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Paper, Container, Typography, Box, TextField, Chip, Snackbar, Alert } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Ventas from './Ventas';
import { CSSTransition } from 'react-transition-group';
import "./AdminPanel.css";
import { Transition } from 'react-transition-group';
import StockView from './StockView';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const AdminPanel = () => {
  const [productos, setProductos] = useState([]);
  const [tallas, setTallas] = useState([]);
  const [colores, setColores] = useState([]);
  const [newTalla, setNewTalla] = useState('');
  const [newColor, setNewColor] = useState('');
  const [showTallaInput, setShowTallaInput] = useState(false);
  const [showColorInput, setShowColorInput] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [newCategoria, setNewCategoria] = useState('');
  const [hoveredCategoria, setHoveredCategoria] = useState(null);
  const [showCategoriaInput, setShowCategoriaInput] = useState(false);
  const [showVentas, setShowVentas] = useState(false);
  const [showStock, setShowStock] = useState(false);
  
  // Estado para almacenar la talla y color "hovered"
  const [hoveredTalla, setHoveredTalla] = useState(null);
  const [hoveredColor, setHoveredColor] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchProductos = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/productos`, { withCredentials: true });
        setProductos(response.data);
      } catch (error) {
        console.error('Error fetching productos:', error);
      }
    };

    const fetchTallas = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/tallas`, { withCredentials: true });
        setTallas(response.data);
      } catch (error) {
        console.error('Error fetching tallas:', error);
      }
    };

    const fetchColores = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/colores`, { withCredentials: true });
        setColores(response.data);
      } catch (error) {
        console.error('Error fetching colores:', error);
      }
    };

    const fetchCategorias = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/categorias`, { withCredentials: true });
        setCategorias(response.data);
      } catch (error) {
        console.error('Error fetching categorias:', error);
      }
    };

    fetchProductos();
    fetchTallas();
    fetchColores();
    fetchCategorias();
  }, []);

  const handleCreateCategoria = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACK_URL}/categorias`, { nombre: newCategoria, withCredentials: true });
      setCategorias([...categorias, response.data]);
      setNewCategoria('');
      setShowCategoriaInput(false);
    } catch (error) {
      console.error('Error creating categoria:', error);
    }
  };

  const handleDeleteCategoria = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACK_URL}/categorias/${id}`, { withCredentials: true });
      setCategorias(categorias.filter((categoria) => categoria.id !== id));
      setSnackbarMessage('Categoria eliminada exitosamente');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error deleting categoria:', error);
      if (error.response && error.response.status === 409) {
        setSnackbarMessage(error.response.data.message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage('Error eliminando la categoria');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  };

  const handleCreateTalla = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACK_URL}/agregar_talla`, { nombre: newTalla, withCredentials: true });
      setNewTalla('');
      setShowTallaInput(false);
      setTallas(tallas => [...tallas, response.data]);
    } catch (error) {
      console.error('Error creating talla:', error);
    }
  };

  const handleCreateColor = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACK_URL}/agregar_color`, { nombre: newColor, withCredentials: true });
      setNewColor('');
      setShowColorInput(false);
      setColores(colores =>[...colores, response.data]);
    } catch (error) {
      console.error('Error creating color:', error);
    }
  };

  const handleDeleteTalla = async (talla_id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACK_URL}/eliminar_talla/${talla_id}`, { withCredentials: true });
      setTallas(tallas.filter(talla => talla.id !== talla_id));
      setSnackbarMessage('Talla eliminada exitosamente');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error deleting talla:', error);
      if (error.response && error.response.status === 409) {
        setSnackbarMessage(error.response.data.message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage('Error eliminando la talla');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  };
  
  const handleDeleteColor = async (color_id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACK_URL}/eliminar_color/${color_id}`, { withCredentials: true });
      setColores(colores.filter(color => color.id !== color_id));
      setSnackbarMessage('Color eliminado exitosamente');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error deleting color:', error);
      if (error.response && error.response.status === 409) {
        setSnackbarMessage(error.response.data.message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage('Error eliminando el color');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  };

  const handleDeleteProducto = async (producto_id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACK_URL}/eliminar_producto/${producto_id}`, { withCredentials: true });
      setProductos(productos.filter(producto => producto.id !== producto_id));
      setSnackbarMessage('Producto eliminado exitosamente');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error deleting producto:', error);
      setSnackbarMessage('Error eliminando el producto');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <Container sx={{ mt: isMobile ? 18 : 12 }}>
      <Typography variant="h3" gutterBottom align="center">Admin Panel</Typography>
      
      {/* Tabla de Productos */}
      <Paper style={{ marginBottom: '20px', padding: '20px' }}>
      <Typography variant="h5" gutterBottom>Productos Existentes</Typography>
      <div style={{ overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Descripción</TableCell>
              <TableCell>Precio</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productos.map((producto) => (
              <TableRow key={producto.id}>
                <TableCell>{producto.nombre}</TableCell>
                <TableCell>{producto.descripcion}</TableCell>
                <TableCell>{producto.precio}</TableCell>
                <TableCell>
                  <Button component={Link} to={`/admin/editar_producto/${producto.id}`} variant="contained" color="primary" style={{ marginRight: '10px' }}>
                    Editar
                  </Button>
                  <Button variant="contained" color="error" onClick={() => handleDeleteProducto(producto.id)}>
                    Eliminar
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </div>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button component={Link} to="/admin/crear_producto" variant="contained" color="primary">
            <AddIcon /> Crear Producto
          </Button>
        </Box>
      </Paper>

      {/* Menu desplegable de Tallas */}
      <Paper style={{ marginBottom: '20px', padding: '20px' }}>
        <Typography variant="h5" gutterBottom>Tallas</Typography>
        <Box sx={{ display: 'flex', gap: 1, marginBottom: 2 }}>
          {tallas.map((talla) => (
            <Chip
              key={talla.id}
              label={talla.nombre}
              color={hoveredTalla === talla.nombre ? 'error' : 'default'}
              onMouseEnter={() => setHoveredTalla(talla.nombre)}
              onMouseLeave={() => setHoveredTalla(null)}
              onClick={() => handleDeleteTalla(talla.id)}
              sx={{ 
                minWidth: 60,
                cursor: 'pointer'
              }}
            />
          ))}
        </Box>
        {showTallaInput ? (
          <Box display="flex" alignItems="center" mt={2}>
            <TextField
              label="Nueva Talla"
              value={newTalla}
              onChange={(e) => setNewTalla(e.target.value)}
              variant="outlined"
              size="small"
              style={{ marginRight: '10px' }}
            />
            <Button onClick={handleCreateTalla} variant="contained" color="secondary">
              Guardar Talla
            </Button>
          </Box>
        ) : (
          <Button startIcon={<AddIcon />} onClick={() => setShowTallaInput(true)} variant="contained" color="secondary" style={{ marginTop: '10px' }}>
            Agregar Talla
          </Button>
        )}
      </Paper>

      {/* Menu desplegable de Colores */}
      <Paper style={{ padding: '20px', marginBottom: '20px' }}>
        <Typography variant="h5" gutterBottom>Colores</Typography>
        <Box sx={{ display: 'flex', gap: 1, marginBottom: 2 }}>
          {colores.map((color) => (
            <Chip
              key={color.id}
              label={color.nombre}
              color={hoveredColor === color.nombre ? 'error' : 'default'}
              onMouseEnter={() => setHoveredColor(color.nombre)}
              onMouseLeave={() => setHoveredColor(null)}
              onClick={() => handleDeleteColor(color.id)}
              sx={{ 
                minWidth: 60,
                cursor: 'pointer'
              }}
            />
          ))}
        </Box>
        {showColorInput ? (
          <Box display="flex" alignItems="center" mt={2}>
            <TextField
              label="Nuevo Color"
              value={newColor}
              onChange={(e) => setNewColor(e.target.value)}
              variant="outlined"
              size="small"
              style={{ marginRight: '10px' }}
            />
            <Button onClick={handleCreateColor} variant="contained" color="secondary">
              Guardar Color
            </Button>
          </Box>
        ) : (
          <Button startIcon={<AddIcon />} onClick={() => setShowColorInput(true)} variant="contained" color="secondary" style={{ marginTop: '10px' }}>
            Agregar Color
          </Button>
        )}
      </Paper>

      {/* Menu desplegable de Categorias */}
      <Paper style={{ padding: '20px', marginBottom: '20px' }}>
        <Typography variant="h5" gutterBottom>Categorias</Typography>
        <Box sx={{ display: 'flex', gap: 1, marginBottom: 2 }}>
          {categorias.map((categoria) => (
            <Chip
              key={categoria.id}
              label={categoria.nombre}
              color={hoveredCategoria === categoria.nombre ? 'error' : 'default'}
              onMouseEnter={() => setHoveredCategoria(categoria.nombre)}
              onMouseLeave={() => setHoveredCategoria(null)}
              onClick={() => handleDeleteCategoria(categoria.id)}
              sx={{ 
                minWidth: 60,
                cursor: 'pointer'
              }}
            />
          ))}
        </Box>
        {showCategoriaInput ? (
          <Box display="flex" alignItems="center" mt={2}>
            <TextField
              label="Nueva Categoria"
              value={newCategoria}
              onChange={(e) => setNewCategoria(e.target.value)}
              variant="outlined"
              size="small"
              style={{ marginRight: '10px' }}
            />
            <Button onClick={handleCreateCategoria} variant="contained" color="secondary">
              Guardar Categoria
            </Button>
          </Box>
        ) : (
          <Button startIcon={<AddIcon />} onClick={() => setShowCategoriaInput(true)} variant="contained" color="secondary" style={{ marginTop: '10px' }}>
            Añadir Categoria
          </Button>
        )}
      </Paper>

      {/* Apartado de Ventas */}
      <Paper style={{ padding: '20px' }}>
        <Box mb={4}>
          <Typography variant="h5">Ventas</Typography>
          <Button onClick={() => setShowVentas(!showVentas)} variant="contained" color="primary" style={{ marginTop: '20px' }}>
            {showVentas ? 'Ocultar Ventas' : 'Mostrar Ventas'}
          </Button>
        </Box>

        <CSSTransition
          in={showVentas}
          timeout={600}
          classNames="ventas-transition"
          unmountOnExit
        >
          <div className="ventas-container" style={{ overflowX: 'auto' }}>
              <Ventas />
          </div>
        </CSSTransition>
      </Paper>

      {/* Stock View */}
      <Paper style={{ padding: '20px', marginTop: '20px' }}>
        <Box mb={4}>
          <Typography variant="h5">Stock</Typography>
          <Button onClick={() => setShowStock(!showStock)} variant="contained" color="primary" style={{ marginTop: '20px' }}>
            {showStock ? 'Ocultar Stock' : 'Mostrar Stock'}
          </Button>
        </Box>

        <CSSTransition
          in={showStock}
          timeout={600}
          classNames="stock-transition"
          unmountOnExit
        >
          <div className="stock-container" style={{ overflowX: 'auto' }}>
              <StockView />
          </div>
        </CSSTransition>
      </Paper>

      <Box mb={4} />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AdminPanel;
