import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Typography, Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const Unauthorized = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: isMobile ? 18 : 12, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h4" color="error" gutterBottom>
          Acceso no autorizado
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          No tienes permiso para ver esta página.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGoBack}
          sx={{ mt: 3 }}
        >
          Volver a la página principal
        </Button>
      </Box>
    </Container>
  );
};

export default Unauthorized;